
import Swal from "sweetalert2";
import Vue from "vue";
import VueParallaxJs from "vue-parallax-js";
import DonateUpgrade from "../components/DonateUpgrade.vue";
import { getCountryName } from "../services/countryCodes";
import { ServiceSpotify } from "../services/ServiceSpotify";
import { Account, AccountCountry } from "../shared/interfaces";

Vue.use(VueParallaxJs);

var serviceSpotify = new ServiceSpotify();
var defaultBtn = "UPGRADE";
var interval;
var timeouts;

var isoCountries = {
  AF: "Afghanistan",
  AX: "Aland Islands",
  AL: "Albania",
  DZ: "Algeria",
  AS: "American Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua And Barbuda",
  AR: "Argentina",
  AM: "Armenia",
  AW: "Aruba",
  AU: "Australia",
  AT: "Austria",
  AZ: "Azerbaijan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Belarus",
  BE: "Belgium",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnia And Herzegovina",
  BW: "Botswana",
  BV: "Bouvet Island",
  BR: "Brazil",
  IO: "British Indian Ocean Territory",
  BN: "Brunei Darussalam",
  BG: "Bulgaria",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodia",
  CM: "Cameroon",
  CA: "Canada",
  CV: "Cape Verde",
  KY: "Cayman Islands",
  CF: "Central African Republic",
  TD: "Chad",
  CL: "Chile",
  CN: "China",
  CX: "Christmas Island",
  CC: "Cocos (Keeling) Islands",
  CO: "Colombia",
  KM: "Comoros",
  CG: "Congo",
  CD: "Congo, Democratic Republic",
  CK: "Cook Islands",
  CR: "Costa Rica",
  CI: "Cote D'Ivoire",
  HR: "Croatia",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominican Republic",
  EC: "Ecuador",
  EG: "Egypt",
  SV: "El Salvador",
  GQ: "Equatorial Guinea",
  ER: "Eritrea",
  EE: "Estonia",
  ET: "Ethiopia",
  FK: "Falkland Islands (Malvinas)",
  FO: "Faroe Islands",
  FJ: "Fiji",
  FI: "Finland",
  FR: "France",
  GF: "French Guiana",
  PF: "French Polynesia",
  TF: "French Southern Territories",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Germany",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Greece",
  GL: "Greenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  GY: "Guyana",
  HT: "Haiti",
  HM: "Heard Island & Mcdonald Islands",
  VA: "Holy See (Vatican City State)",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IR: "Iran, Islamic Republic Of",
  IQ: "Iraq",
  IE: "Ireland",
  IM: "Isle Of Man",
  IL: "Israel",
  IT: "Italy",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordan",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KR: "Korea",
  KW: "Kuwait",
  KG: "Kyrgyzstan",
  LA: "Lao People's Democratic Republic",
  LV: "Latvia",
  LB: "Lebanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyan Arab Jamahiriya",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MO: "Macao",
  MK: "Macedonia",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshall Islands",
  MQ: "Martinique",
  MR: "Mauritania",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesia, Federated States Of",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolia",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Morocco",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Netherlands",
  AN: "Netherlands Antilles",
  NC: "New Caledonia",
  NZ: "New Zealand",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolk Island",
  MP: "Northern Mariana Islands",
  NO: "Norway",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestinian Territory, Occupied",
  PA: "Panama",
  PG: "Papua New Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Reunion",
  RO: "Romania",
  RU: "Russian Federation",
  RW: "Rwanda",
  BL: "Saint Barthelemy",
  SH: "Saint Helena",
  KN: "Saint Kitts And Nevis",
  LC: "Saint Lucia",
  MF: "Saint Martin",
  PM: "Saint Pierre And Miquelon",
  VC: "Saint Vincent And Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tome And Principe",
  SA: "Saudi Arabia",
  SN: "Senegal",
  RS: "Serbia",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  SB: "Solomon Islands",
  SO: "Somalia",
  ZA: "South Africa",
  GS: "South Georgia And Sandwich Isl.",
  ES: "Spain",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Suriname",
  SJ: "Svalbard And Jan Mayen",
  SZ: "Swaziland",
  SE: "Sweden",
  CH: "Switzerland",
  SY: "Syrian Arab Republic",
  TW: "Taiwan",
  TJ: "Tajikistan",
  TZ: "Tanzania",
  TH: "Thailand",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad And Tobago",
  TN: "Tunisia",
  TR: "Turkey",
  TM: "Turkmenistan",
  TC: "Turks And Caicos Islands",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UM: "United States Outlying Islands",
  UY: "Uruguay",
  UZ: "Uzbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Virgin Islands, British",
  VI: "Virgin Islands, U.S.",
  WF: "Wallis And Futuna",
  EH: "Western Sahara",
  YE: "Yemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
};

function defineCountries() {
  var keys = Object.keys(isoCountries);
  var array = [];
  keys.forEach((key) => {
    array.push({
      code: key,
      label: isoCountries[key],
    });
  });
  return array;
}

function emptyFieldError(input: string) {
  Swal.fire({
    title: "No " + input + "!",
    text: "Please fill " + input + " input",
    icon: "error",
    confirmButtonText: "Cool",
  });
}

function resultError(error: string) {
  Swal.fire({
    title: "Error!",
    text: error,
    icon: "error",
    confirmButtonText: "Cool",
  });
}

function inputTokenToUpgrade(account: Account) {
  Swal.insertQueueStep({
    title: "Submit your token to upgrade your account",
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: true,
    confirmButtonText: "Upgrade",
    showLoaderOnConfirm: true,
    preConfirm: (token) => {
      account.token = token;
      console.log("ACCOUNT::", account);
      return serviceSpotify
        .upgradeAccount(account)
        .then((result) => {
          console.log("RESULT::", result);
          if (result["error"]) {
            throw new Error(result["error"]);
          } else if (result["success"]) {
            var success = result["success"];
            Swal.insertQueueStep({
              title: "Success",
              icon: "info",
              html: generateHtmlSuccess(success),
            });
          } else {
            throw new Error("Unkown Error");
          }
        })
        .catch((error) => {
          Swal.showValidationMessage(`${error}`);
        });
    },
  });
}

function modalRegister(account: Account, serviceSpotify) {
  Swal.queue([
    {
      title: "Account doesn't exist",
      confirmButtonText: "REGISTER",
      html: "This account doesn't exist in Spotify.<br>Register it now pressing on 'Register' button",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return serviceSpotify
          .registerAccount(account)
          .then((result) => {
            if (result["status"] == 1) {
              var success =
                "Account created successfuly in " +
                getCountryName(result["country"]);
              Swal.insertQueueStep({
                title: "Success",
                icon: "info",
                html: generateHtmlSuccess(success),
              });
            } else {
              var error = "Unable to create the account";
              if (result.error) {
                error = result.error;
              }
              Swal.insertQueueStep({
                title: "Error",
                icon: "error",
                html: generateHtmlError(error),
              });
            }
          })
          .catch(() => {
            Swal.insertQueueStep({
              icon: "error",
              title: "Unable to register account",
            });
          });
      },
    },
  ]);
}

function generateHtmlAccount(account: any) {
  var htmlOutPut = "<div class='account_popup'>";
  htmlOutPut +=
    "<p><strong>Username: </strong>" + account.username + "</strong>";
  htmlOutPut += "<p><strong>Password: </strong>*****</p>";
  htmlOutPut +=
    "<p><strong>Subscription: </strong>" + account.subscription + "</p>";
  htmlOutPut +=
    "<p><strong>Country: </strong>" +
    getCountryName(account.country) +
    "</p><div>";
  return htmlOutPut;
}

function accountAlreadyPremium(account: any) {
  Swal.fire({
    title: "Your account is already premium!",
    html: generateHtmlAccount(account),
    icon: "warning",
    confirmButtonText: "Okay",
  });
}

function generateHtmlSuccess(input: string) {
  var htmlOutPut = "<div class='account_popup success'>";
  htmlOutPut += "<p><strong>" + input + "</strong></p>";
  htmlOutPut += "<div>";
  return htmlOutPut;
}

function generateHtmlError(input: string) {
  var htmlOutPut = "<div class='account_popup error'>";
  htmlOutPut += "<p><strong>" + input + "</strong></p>";
  htmlOutPut += "<div>";
  return htmlOutPut;
}

function accountSuccess(
  account: any,
  inputAccount: Account,
  invalid: AccountInvalid
) {
  Swal.queue([
    {
      title: "Your Account Information",
      html: generateHtmlAccount(account),
      icon: "success",
      confirmButtonText: "Upgrade",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        if (inputAccount.token) {
          account.token = inputAccount.token;
        }
        return serviceSpotify
          .upgradeAccount(account)
          .then((result) => {
            console.log(result);
            if (result["success"]) {
              var success = result["success"];
              if (success.includes("linked to a working token")) {
                Swal.insertQueueStep({
                  title: "Success",
                  icon: "info",
                  html: generateHtmlSuccess(success),
                });
              } else {
                Swal.insertQueueStep({
                  title: "Success",
                  icon: "success",
                  html: generateHtmlSuccess(success),
                });
              }
            } else if (result["error"]) {
              var error = result["error"];
              console.log("ERROR TOKEN::", error);
              if (error.includes("send the upgrade token here")) {
                return { insert_token: true };
              } else {
                Swal.insertQueueStep({
                  icon: "error",
                  title: "Error",
                  html: generateHtmlError(error),
                });
              }
            }
            return result;
          })
          .then((result) => {
            if (result["token"]) {
              account.token = result["token"];
              serviceSpotify.upgradeAccount(account).then((result: any) => {
                if (result.success) {
                  Swal.fire({
                    title: "Success",
                    icon: "success",
                    html: generateHtmlSuccess(result["success"]),
                  });
                } else if (result.error) {
                  Swal.fire({
                    title: "Error",
                    icon: "error",
                    html: generateHtmlError(result["error"]),
                  });
                }
              });
            } else if (result["insert_token"]) {
              inputTokenToUpgrade(account);
            }
          });
      },
    },
  ]);
}

function invalidError(input: string) {
  Swal.fire({
    title: "Invalid " + input + "!",
    text: "Please fill " + input + " input with a valid " + input,
    icon: "error",
    confirmButtonText: "Cool",
  });
}

function validateEmail(email: string) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

async function registerAccount(account: Account, invalid: AccountInvalid) {
  return new Promise((resolve) => {
    var returnValue: boolean = true;
    var field: string = "";
    if (!account) {
      console.log("NO ACCOUNT");
    }
    if (!account.email) {
      field = "email";
      emptyFieldError(field);
      returnValue = false;
    } else if (account.email) {
      var isEmailValid = validateEmail(account.email);
      if (!isEmailValid) {
        field = "Invalid Email";
        resultError(field);
        returnValue = false;
      } else if (!account.password) {
        field = "password";
        emptyFieldError(field);
        returnValue = false;
      }
      var password = account.password;
      if (account.password && password.length < 4) {
        field = "Invalid password, too short";
        resultError(field);
        returnValue = false;
      }
    }
    if (!field || field == "") {
      var serviceSpotify = new ServiceSpotify();
      serviceSpotify.registerAccount(account).then((result: any) => {
        if (result["status"] == 1) {
          var success =
            "Account created successfuly in " +
            getCountryName(result["country"]);
          Swal.fire({
            title: "Success",
            icon: "info",
            html: generateHtmlSuccess(success),
          });
        } else {
          var error = "Unable to create the account";
          if (result.error) {
            error = result.error;
          }
          Swal.fire({
            title: "Error",
            icon: "error",
            html: generateHtmlError(error),
          });
        }
        resolve(true);
      });
    } else {
      resolve(true);
    }
  });
}

async function changeCountryAccount(account) {
  return new Promise((resolve) => {
    var returnValue: boolean = true;
    var field: string = "";
    if (!account) {
      console.log("NO ACCOUNT");
    }
    if (!account.email) {
      field = "email";
      emptyFieldError(field);
      returnValue = false;
    } else if (account.email) {
      var isEmailValid = validateEmail(account.email);
      if (!isEmailValid) {
        field = "Invalid Email";
        resultError(field);
        returnValue = false;
      } else if (!account.password) {
        field = "password";
        emptyFieldError(field);
        returnValue = false;
      } else if (!account.country) {
        field = "country";
        emptyFieldError(field);
        returnValue = false;
      }
      var password = account.password;
      if (account.password && password.length < 4) {
        field = "Invalid password, too short";
        resultError(field);
        returnValue = false;
      }
    }
    if (!field || field == "") {
      var serviceSpotify = new ServiceSpotify();
      serviceSpotify.changeCountry(account).then((result) => {
        console.log("RESULT:::", result);
        if (result["success"]) {
          var success = result["success"];
          Swal.fire({
            title: "Success",
            icon: "info",
            html: generateHtmlSuccess(success),
          });
        } else {
          var error = result["error"];
          Swal.fire({
            title: "Error",
            icon: "error",
            html: generateHtmlError(error),
          });
        }
        resolve(true);
      });
    } else {
      resolve(true);
    }
  });
}

async function validateInput(account: Account, invalid: AccountInvalid) {
  var returnValue: boolean = true;
  var field: string = "";
  if (!account) {
    console.log("NO ACCOUNT");
  }
  if (!account.email) {
    field = "email";
    emptyFieldError(field);
    returnValue = false;
  } else if (account.email) {
    var isEmailValid = validateEmail(account.email);
    isEmailValid = true;
    if (!isEmailValid) {
      field = "email";
      invalidError(field);
      returnValue = false;
    } else if (!account.password) {
      field = "password";
      emptyFieldError(field);
      returnValue = false;
    }
    var password = account.password;
    if (account.password && password.length < 4) {
      field = "Invalid password, too short";
      resultError(field);
      returnValue = false;
    }
  }
  if (field) {
    console.log(field);
    setInvalid(field, invalid);
  } else {
    var result: any = await serviceSpotify.checkAccount(account);
    defaultBtn = defaultBtn;
    if (result["error"]) {
      var error = result["error"];
      if (error == "Bad Account") {
        var checkExists: any = await serviceSpotify.checkExists(account);
        var errorSend;
        if (checkExists["error"] || !validateEmail(account["email"])) {
          errorSend = result["error"];
          resultError(
            "Incorrect Email / Username or Password from Spotify. Try again. In case it keeps failing check your mail box for password resets"
          );
        } else if (checkExists["success"]) {
          modalRegister(account, serviceSpotify);
        }
      }
    } else if (result["resultAcc"]) {
      var success: any = result["resultAcc"];
      if (success["subscription"] == "Spotify Free") {
        accountSuccess(success, account, invalid);
      } else {
        accountAlreadyPremium(success);
      }
    }
  }
}

function setInvalid(field: string, invalid: AccountInvalid) {
  if (field == "email") {
    invalid.email = true;
  }
  if (field == "password") {
    invalid.password = true;
  }
  if (field == "token") {
    invalid.token = true;
  }
}

interface AccountInvalid {
  email: boolean;
  password: boolean;
  token: boolean;
}

function dynamicLoading(self) {
  timeouts = setTimeout(function () {
    self.btnText = "Loading.";
    timeouts = setTimeout(function () {
      self.btnText = "Loading..";
      timeouts = setTimeout(function () {
        self.btnText = "Loading...";
      }, 250);
    }, 250);
  }, 250);
}

function dynamicLoadingRegister(self) {
  timeouts = setTimeout(function () {
    self.btnRegister = "Loading.";
    timeouts = setTimeout(function () {
      self.btnRegister = "Loading..";
      timeouts = setTimeout(function () {
        self.btnRegister = "Loading...";
      }, 250);
    }, 250);
  }, 250);
}

function dynamicLoadingCountry(self) {
  timeouts = setTimeout(function () {
    self.btnTextCountry = "Loading.";
    timeouts = setTimeout(function () {
      self.btnTextCountry = "Loading..";
      timeouts = setTimeout(function () {
        self.btnTextCountry = "Loading...";
      }, 250);
    }, 250);
  }, 250);
}

const allCountries = defineCountries();
const registerCodes = ["NZ", "US", "AU", "GB", "BR", "CA", "NO"];

export default Vue.extend({
  name: "Upgrade" as string,
  components: {
    DonateUpgrade,
  },
  data() {
    return {
      someCountries: allCountries.filter((el) =>
        registerCodes.includes(el.code)
      ),
      allCountries: allCountries,
      loading: false,
      loadingRegister: false,
      loadingCountry: false,
      btnText: "UPGRADE",
      btnTextCountry: "CHANGE COUNTRY",
      btnRegister: "REGISTER",
      invalid: {} as AccountInvalid,
      account: {} as Account,
      register: {} as Account,
      accountCountry: {} as AccountCountry,
    };
  },
  methods: {
    onSubmit() {
      this.invalid = {
        email: false,
        password: false,
        token: false,
      };
      var self = this;
      self.loading = true;
      var field = validateInput(this.account, this.invalid).then((result) => {
        self.loading = false;
      });
      return false;
    },
    onSubmitCountry() {
      var account = this.accountCountry;
      var self = this;
      self.loadingCountry = true;
      var field = changeCountryAccount(this.accountCountry).then((result) => {
        self.loadingCountry = false;
      });
      return false;
    },
    registerSubmit() {
      this.invalid = {
        email: false,
        password: false,
        token: false,
      };
      var self = this;
      self.loadingRegister = true;
      var field = registerAccount(this.register, this.invalid).then(
        (result) => {
          self.loadingRegister = false;
        }
      );
      return false;
    },
  },
  watch: {
    // a computed getter
    loading: function (newValue, oldValue) {
      console.log(newValue, oldValue);
      var self = this;
      if (newValue == true) {
        self.btnText = "Loading";
        dynamicLoading(self);
        interval = setInterval(function () {
          dynamicLoading(self);
        }, 1000);
      } else {
        clearInterval(interval);
        clearTimeout(timeouts);
        while (self.btnText.includes("Loading")) {
          self.btnText = "UPGRADE";
        }
      }
    },
    loadingRegister: function (newValue, oldValue) {
      console.log("REGISTER:", newValue, oldValue);
      var self = this;
      if (newValue == true) {
        self.btnRegister = "Loading";
        dynamicLoadingRegister(self);
        interval = setInterval(function () {
          dynamicLoadingRegister(self);
        }, 1000);
      } else {
        clearInterval(interval);
        clearTimeout(timeouts);
        while (self.btnRegister.includes("Loading")) {
          self.btnRegister = "REGISTER";
        }
      }
    },
    loadingCountry: function (newValue, oldValue) {
      console.log("REGISTER:", newValue, oldValue);
      var self = this;
      if (newValue == true) {
        self.btnTextCountry = "Loading";
        dynamicLoadingCountry(self);
        interval = setInterval(function () {
          dynamicLoadingCountry(self);
        }, 1000);
      } else {
        clearInterval(interval);
        clearTimeout(timeouts);
        while (self.btnTextCountry.includes("Loading")) {
          self.btnTextCountry = "CHANGE COUNTRY";
        }
      }
    },
  },
  beforeMount() {
    if (this.$route.params.username && this.$route.params.password) {
      this.account.email = this.$route.params.username;
      this.account.password = this.$route.params.password;
      this.onSubmit();
    }
    $(function () {
      var elements: any = $('[data-toggle="tooltip"]');
      elements.tooltip();
    });
  },
});
